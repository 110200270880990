import { createMuiTheme } from '@material-ui/core'

import { declarations } from './fonts'
import { light } from './palette'
import typography from './typography'
import breakpoints from './breakpoints'

const getTheme = () =>
  // responsiveFontSizes(
  createMuiTheme({
    palette: light,
    breakpoints: breakpoints,
    typography: typography,
    layout: {
      contentWidth: 1236,
    },
    zIndex: {
      appBar: 1200,
      drawer: 1100,
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': Object.values(declarations).map((declaration) => {
            return {
              fontFamily: declaration.fontFamily,
              fontStyle: 'normal',
              fontDisplay: 'swap',
              fontWeight: declaration.fontWeight,
              src: `
                  url("/fonts/${declaration.basename}/${declaration.basename}.woff2") format("woff2"), 
                  url("/fonts/${declaration.basename}/${declaration.basename}.woff") format("woff");
                `,
            }
          }),
          html: {
            fontSize: '62.5%',
          },
        },
      },
      MuiButton: {
        root: {
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 500,
          fontSize: '1.6rem',
          borderRadius: '0.5rem',
          '& svg': {
            color: 'inherit',
          },
        },
        outlined: {
          '&:hover': {
            backgroundColor: 'green',
          },
        },
        outlinedPrimary: {
          color: light.colors.blueTwo,
          borderColor: light.colors.blueTwo,
          '&:hover': {
            color: light.colors.pureWhite,
            backgroundColor: light.colors.blueTwo,
          },
        },
        outlinedSecondary: {
          color: light.colors.pureWhite,
          borderColor: light.colors.pureWhite,
          '&:hover': {
            color: light.colors.dark,
            backgroundColor: light.colors.pureWhite,
            borderColor: light.colors.pureWhite,
          },
        },
        containedSecondary: {
          color: light.colors.blueTwo,
          borderColor: light.colors.pureWhite,
          backgroundColor: light.colors.pureWhite,
          '&:hover': {
            color: light.colors.pureWhite,
            backgroundColor: light.colors.blueTwo,
            borderColor: light.colors.blueTwo,
          },
        },
        sizeLarge: {
          ...typography.h4,
          borderRadius: '1rem',
          height: '6rem',
        },
      },
      MuiSelect: {
        icon: {
          width: '2.2rem',
          height: '2.2rem',
          fill: light.colors.blueTwo,
        },
        root: {
          color: light.colors.blueTwo,
        },
        outlined: {
          borderColor: light.colors.blueTwo,
          borderWidth: '1px !important',
        },
        select: {
          padding: '1rem 2rem',
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiFormHelperText: {
        root: {
          ...typography.smallRegular,
          color: light.colors.tomato,
        },
        contained: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
  })
// )

export default getTheme
