import { Contentful_Link } from '../../types/gatsby-types'
import { LinkProps, TextLinkProps } from '../../components/Link'
import { Icons } from '../../components/Icon/types'

export type SocialType = LinkProps & {
  icon: Icons
}

export const LinkTransformer = (data: Contentful_Link | null | undefined): LinkProps => ({
  route: data?.url ?? '',
  title: data?.label ?? '',
  target: data?.target ?? '',
  special: data?.special ?? false,
})

export const LinkTextTransformer = (data: Contentful_Link | null | undefined): TextLinkProps => ({
  ...LinkTransformer(data),
  text: data?.label ?? '',
})

export const LinkIconTransformer = (data: Contentful_Link | null | undefined): SocialType => ({
  ...LinkTextTransformer(data),
  icon: PictoTransformer(data?.icon || ''),
})

export const PictoTransformer = (data: string): Icons => {
  switch (data) {
    case 'facebook':
      return Icons.facebook
    case 'instagram':
      return Icons.instagram
    case 'linkedin':
      return Icons.linkedin
    case 'profile':
      return Icons.profile
    case 'tiktok':
      return Icons.tiktok
    case 'twitter':
      return Icons.twitter
    case 'youtube':
      return Icons.youtube
  }

  return Icons.instagram
}
