import React, { FC } from 'react'
import cx from 'classnames'

import { SeoProps } from '../Seo'

import * as SC from './styled'

type LayoutProps = {
  className?: string
  header?: React.ReactNode
  footer?: React.ReactNode
  seo?: SeoProps
}

const Layout: FC<LayoutProps> = (props) => {
  const { children, className, header = null, footer = null, seo = {} } = props

  return (
    <SC.Container className={cx(className)}>
      <>{header}</>
      <main>{children}</main>
      <>{footer}</>
    </SC.Container>
  )
}

export default Layout
