// eslint-disable-next-line
import { PaletteType } from '@material-ui/core';

export const light = {
  alternate: {
    main: 'rgb(247, 249, 250)',
    dark: '#e8eaf6',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  type: 'light' as PaletteType,
  primary: {
    main: '#207FDD',
    light: '#479DF1',
    dark: '#479DF1',
    contrastText: '#fff',
  },
  secondary: {
    light: '#ffb74d',
    main: '#f9b934',
    dark: '#f57c00',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  colors: {
    midnightBlue: '#1D286A',
    tomato: '#FF5E4D',
    freeSpeechBlue: '#3F50B5',
    grey: '#888888',
    kournikova: '#FFCB48',
    chambray: '#44566C',
    neonCarrot: '#FF9A2E',
    cornflowerBlue: '#6B83FF',
    whiteSmoke: '#F8F8F8',
    lightViolet: '#BAC0E5',
    pureWhite: '#FFF',
    lightGrey: '#DADADA',
  },
  text: {
    primary: '#303438',
    secondary: '#303438',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    ghostWhite: '#F1F3FF',
    paper: '#fff',
    default: '#fff',
    level2: '#f5f5f5',
    level1: '#fff',
    footer: '#1b1642',
  },
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    colors?: any
  }

  // allow configuration using `createMuiTheme`
  interface PaletteOptions {
    colors?: any
  }
}
