import React, { FC } from 'react'
import cx from 'classnames'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImageProps, getImage, IGatsbyImageData } from 'gatsby-plugin-image'

import * as SC from './styled'

export type ImageGatsbyProps = Omit<GatsbyImageProps, 'image'> & {
  image: string | GatsbyImageProps['image']
  className?: string
  otherProps?: any
}

const ImageGatsby: FC<ImageGatsbyProps> = ({ image, className, alt = '', ...otherProps }) => {
  let displayedImage: IGatsbyImageData | string = image

  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        nodes {
          gatsbyImageData
          parent {
            ... on File {
              name
            }
          }
        }
      }
    }
  `)

  if (typeof displayedImage === 'string') {
    displayedImage = image
      ? data?.allImageSharp?.nodes?.find((node: any) => node.parent.name === image)
          ?.gatsbyImageData ?? null
      : null
  }

  const imageData = getImage(displayedImage as IGatsbyImageData)
  return imageData ? (
    <SC.EnhancedGatsbyImage className={cx(className)} image={imageData} alt={alt} {...otherProps} />
  ) : null
}

export default ImageGatsby
