import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import styled from 'styled-components'

import { Scalars } from '../../types/gatsby-types'
import Link from '../../components/Link'

export enum RichTextTheme {
  Regular = 'Regular',
  WithLegend = 'WithLegend',
}

const isSpacer = (item: string): boolean => {
  return item === '' || item === '\n' || item === ' '
}

export default (
  data: Scalars['Contentful_JSON'],
  theme?: RichTextTheme,
  t: any = null,
  extraParams: { [key: string]: any } = {}
): React.ReactNode =>
  documentToReactComponents(data, {
    // eslint-disable-next-line react/display-name
    renderText: (text: string) =>
      text.indexOf('#onetrust-cookies') >= 0 ? <div id="ot-sdk-cookie-policy"></div> : text,
    renderNode: {
      // eslint-disable-next-line react/display-name
      [INLINES.HYPERLINK]: (node, children) => {
        const InlineLink = styled(Link)`
          color: ${({ theme }) => theme.colors?.primary};
          text-decoration: underline;
        `
        if (node.data.uri === '#cookie-preferences-open') {
          return (
            <InlineLink
              className="js-tc-cookie-preferences"
              onClick={() => {
                typeof window !== 'undefined' &&
                  (window as { tC?: any })?.tC?.privacyCenter?.showPrivacyCenter()
              }}
            >
              {children}
            </InlineLink>
          )
        }
        return <InlineLink link={node.data.uri}>{children}</InlineLink>
      },
      // eslint-disable-next-line react/display-name
      [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
        return (
          <>
            {children && (children.length > 1 || !isSpacer(children[0])) && <p>{children}</p>}
            {children.length <= 1 && isSpacer(children[0]) && <p>&nbsp;</p>}
          </>
        )
      },
      // eslint-disable-next-line react/display-name
      /*[BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        const { id } = node.data.target.sys
        const picture =
          extraParams?.assets?.items?.find((asset: Contentful_Asset) => asset.sys.id === id) ?? null
        const asset = picture ? ImageTransformer(picture) : null
        return <ImageGatsby  {...asset} />
      },*/
    },
  })
