import React from 'react'

export enum Icons {
  accountFAQ = 'accountFAQ',
  award = 'award',
  bed = 'bed',
  bicycle = 'bicycle',
  boat = 'boat',
  book = 'book',
  books = 'books',
  bus = 'bus',
  car = 'car',
  check = 'check',
  chevronDown = 'chevronDown',
  chevronLeft = 'chevronLeft',
  chevronRight = 'chevronRight',
  chevronUp = 'chevronUp',
  close = 'close',
  coin = 'coin',
  facebook = 'facebook',
  filter = 'filter',
  group = 'group',
  headset = 'headset',
  idea = 'idea',
  instagram = 'instagram',
  linkedin = 'linkedin',
  quote = 'quote',
  longArrowRight = 'longArrowRight',
  magic = 'magic',
  mapMarker = 'mapMarker',
  menu = 'menu',
  muted = 'muted',
  unmuted = 'unmuted',
  paperPlane = 'paperPlane',
  plane = 'plane',
  pod = 'pod',
  profile = 'profile',
  profileCheck = 'profileCheck',
  search = 'search',
  shoppingBag = 'shoppingBag',
  soutien = 'soutien',
  stage = 'stage',
  star = 'star',
  studyFAQ = 'studyFAQ',
  subway = 'subway',
  ticket = 'ticket',
  tiktok = 'tiktok',
  twitter = 'twitter',
  videoFAQ = 'videoFAQ',
  walletFAQ = 'walletFAQ',
  wallet = 'wallet',
  youtube = 'youtube',
  family = 'family',
  student = 'student',
  platform = 'platform',
  teacher = 'teacher',
  pebble = 'pebble',
}

export type IconProps = {
  className?: string
  icon: Icons
  color?: string
  style?: React.CSSProperties
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}
