import React, { FC } from 'react'
import { Button as GatsbyButton } from 'gatsby-theme-material-ui'
import { Button as MuiButton } from '@material-ui/core'

import router from '../../router'
import { TextLinkProps } from '../Link'
import { Icons } from '../Icon/types'

import * as SC from './styled'

export type LinkButtonProps = TextLinkProps & {
  className?: string
  color?: 'default' | 'inherit' | 'primary' | 'secondary'
  variant?: 'contained' | 'outlined' | 'text'
  size?: 'large' | 'medium' | 'small'
  icon?: Icons
  iconColor?: string
}

const LinkButton: FC<LinkButtonProps> = (props) => {
  const {
    children,
    className,
    link,
    target,
    title,
    role,
    tabIndex,
    route,
    routeParams,
    rel,
    text,
    onClick = () => undefined,
    color,
    variant,
    size,
    icon,
    iconColor,
  } = props

  const renderIcon = () =>
    icon && <SC.IconIn icon={Icons[icon]} color={iconColor ? iconColor : ''} />

  const renderDefault = () => (
    <MuiButton
      className={className}
      onClick={onClick}
      role={role}
      tabIndex={tabIndex}
      title={title}
      aria-label={title}
      color={color ?? 'primary'}
      variant={variant ?? 'contained'}
      size={size ?? 'medium'}
    >
      {children || text}
      {icon && renderIcon()}
    </MuiButton>
  )

  const renderAsLink = () => {
    if (link) {
      return (
        <MuiButton
          className={className}
          title={title}
          href={link}
          aria-label={title}
          target={target || '_self'}
          onClick={onClick}
          rel={rel || 'noreferrer'}
          role={role}
          tabIndex={tabIndex}
          color={color ?? 'primary'}
          variant={variant ?? 'contained'}
          size={size ?? 'medium'}
        >
          {children || text}
          {icon && renderIcon()}
        </MuiButton>
      )
    }

    return (
      <GatsbyButton
        to={routeParams ? router(route!, routeParams) : route ?? ''}
        className={className}
        title={title}
        aria-label={title}
        onClick={onClick}
        role={role}
        tabIndex={tabIndex}
        color={color ?? 'primary'}
        variant={variant ?? 'contained'}
        size={size ?? 'medium'}
      >
        {children || text}
        {icon && renderIcon()}
      </GatsbyButton>
    )
  }

  return <>{link || route ? renderAsLink() : renderDefault()}</>
}

export default LinkButton
