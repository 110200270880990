import styled from 'styled-components'
import { Container, Drawer, IconButton, Toolbar, Divider } from '@material-ui/core'

import Icon from '../Icon'
import Link from '../Link'
import LinkButton from '../LinkButton'

export const Header = styled.header<{ backgroundColor: string }>`
  background-color: ${(props) => props.backgroundColor};
`
export const Wrapper = styled(Container)`
  position: relative;
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 0 2rem;
  }
`

export const Logo = styled.div``

export const LogoImage = styled.img`
  height: 4rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    height: 5rem;
  }
`

export const Icons = styled(Icon)`
  width: 2.6rem;
  height: 2.6rem;
`

export const MuiToolbar = styled(Toolbar)`
  width: 100%;
  justify-content: space-between;
  &.mobile-menu-open-toolbar {
    position: fixed;
    overflow-y: auto;
    opacity: 0.6;
    background-color: ${(props) => props.theme.palette.colors.pureBlack};
    width: 100%;
    height: 100%;
    align-items: flex-start;
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 0;
  }
`

// mobile menu
export const MuiDrawer = styled(Drawer)`
  /* (MuiPaper) */
  & > * {
    width: 80%;
    top: 0rem;
  }
`

export const CloseIconButton = styled(IconButton)`
  margin-left: 0;
  width: 5rem;
  position: absolute;
  right: 0;
  z-index: 5;
`

export const MobileMenu = styled.nav`
  position: relative;
  z-index: 2;
  padding: 5.5rem 1.6rem 2.5rem 2.4rem;
`

export const MobileList = styled.ul`
  position: relative;
  z-index: 2;
  padding: 0;
  margin: 0;
  list-style: outside none;
  ${(props) => props.theme.breakpoints.up('md')} {
    display: flex;
    flex-direction: row;
  }
`

export const MobileListItem = styled.li`
  position: relative;
  margin: 1.5rem 0;
  max-width: max-content;
  ${(props) => props.theme.breakpoints.up('md')} {
    margin: 0 1.5rem;
  }
`

export const MobileListItemLink = styled(Link)`
  ${(props) => ({ ...props.theme.typography.h5 })}
  text-decoration: none;
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  &:hover {
    color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  }
  &.active {
    color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      height: 1px;
      width: 20%;
      border-bottom: 2px solid ${(props) => props.theme.palette.colors.freeSpeechBlue};
      transform: translateX(-50%);
      border-radius: 0.1rem;
    }
  }
  &.special {
    color: ${(props) => props.theme.palette.colors.tomato};
  }
`

export const MuiDivider = styled(Divider)`
  margin: 1rem 0rem;
`

export const MobileLinkButton = styled(LinkButton)`
  margin: 1rem 0;
  max-width: max-content;
  border-color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  & > span {
    color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
    ${(props) => ({ ...props.theme.typography.h5 })}
  }
  &:hover {
    background-color: ${(props) => props.theme.palette.colors.cornflowerBlue};
    border-color: ${(props) => props.theme.palette.colors.cornflowerBlue};
    & > span {
      color: ${(props) => props.theme.palette.colors.pureWhite};
    }
    & > svg {
      color: ${(props) => props.theme.palette.colors.pureWhite};
    }
  }
`

export const MobileButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const MobileListTinyItemLink = styled(Link)`
  ${(props) => ({ ...props.theme.typography.tinyRegular })}
  text-decoration: none;
  color: ${(props) => props.theme.palette.colors.grey};
  &:hover {
    text-decoration: underline;
  }
`
