import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import Icon from '../Icon'

const styles = css``

export const LinkA = styled.a`
  ${styles}
`

export const LinkGatsby = styled(Link)`
  ${styles}
`

export const IconIn = styled(Icon)`
  width: 1.8rem;
  height: 1.8rem;
  margin-left: 1rem;
`
