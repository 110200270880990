import { Container, Typography } from '@material-ui/core'
import styled, { css } from 'styled-components'

import Icon from '../Icon'
import Link from '../Link'

const commonLink = css`
  color: ${(props) => props.theme.palette.colors.blueMain};
  transition: color 200ms ease-in;
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.palette.colors.blueTwo};
  }
`

export const Footer = styled.footer`
  background-color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin-top: auto;
  text-align: center;
  ${(props) => props.theme.breakpoints.up('md')} {
    text-align: left;
  }
`

export const Wrapper = styled(Container)`
  padding-top: 4rem;
  padding-bottom: 2.5rem;
  border-top: 1px solid ${(props) => props.theme.palette.colors.blueUltraLight};
  ${(props) => props.theme.breakpoints.up('md')} {
    padding-top: 2rem;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) => props.theme.breakpoints.up('md')} {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: left;
  }
`

export const Logo = styled.div`
  display: flex;
`

export const LogoImage = styled.img`
  height: 10rem;
`

export const Groups = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none outside;
  margin: 0;
  padding: 0;
`

export const GroupsItem = styled.div`
  max-width: 12rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 2rem;
  &:nth-child(2 + n) {
    margin-top: 4rem;
  }
  &:nth-child(2n + 1) {
    margin-right: 6rem;
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 16rem;
    margin-top: 5rem;
    margin-right: 6rem;
  }
`

export const GroupsTitle = styled(Typography)`
  ${(props) => ({ ...props.theme.typography.h5 })}
  color: ${(props) => props.theme.palette.colors.kournikova};
`

export const LinksLi = styled.li`
  text-align: initial;
`

export const LinksLink = styled(Link)`
  ${(props) => ({ ...props.theme.typography.mediumRegular })}
  color: ${(props) => props.theme.palette.background.paper};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

export const Socials = styled.ul`
  display: flex;
  flex-flow: row wrap;
  list-style: none outside;
  margin: 1rem 0;
  padding: 0;
  & > li:not(:first-child) {
    margin-left: 3.5rem;
  }
`

export const SocialsLink = styled(Link)`
  ${commonLink}
  color: ${(props) => props.theme.palette.colors.pureWhite};
`

export const SocialsIcon = styled(Icon)`
  width: 3.2rem;
  height: 3.2rem;
`

export const Subcontent = styled.div`
  margin-top: 4rem;
  text-align: initial;
  ${(props) => props.theme.breakpoints.up('md')} {
    display: flex;
  }
`

export const Text = styled.div`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.background.ghostWhite};
  ${(props) => props.theme.breakpoints.up('md')} {
    display: inline;
  }
`

export const OtherLinks = styled.ul`
  text-align: initial;
  list-style: none outside;
  margin: 0;
  padding: 0;
  ${(props) => props.theme.breakpoints.up('md')} {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    max-width: max-content;
    margin-left: 2rem;
  }
`

export const LegalLink = styled(Link)`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.background.ghostWhite};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    display: inline;
    margin-right: 1rem;
  }
`

export const Address = styled.ul`
  text-align: initial;
  list-style: none outside;
  margin: 0;
  padding: 0;
  margin-top: 3rem;
  width: 100%;
  align-items: left;
  ${(props) => props.theme.breakpoints.up('md')} {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: max-content;
  }
`
