import React, { FC, useState, useEffect } from 'react'
import { IconButton, Hidden } from '@material-ui/core'

import { Icons } from '../Icon/types'
import Link, { LinkProps, TextLinkProps } from '../Link'
import useWindowSize from '../../hooks/useWindowSize'
import { ImgProps } from '../../types/image'
import breakpoints from '../../theme/breakpoints'

import * as SC from './styled'

export type MenuMobileProps = {
  signup?: LinkProps[]
  others?: LinkProps[]
}

export type HeaderProps = {
  className?: string
  logo: ImgProps
  menu?: TextLinkProps[]
  connectLinkProps?: TextLinkProps
  menuMobile?: MenuMobileProps
  backgroundColor: string
  homeLink?: string
}

const Header: FC<HeaderProps> = (props) => {
  const { className, logo, menu, menuMobile, backgroundColor, homeLink } = props
  const [isMobile, setIsMobile] = useState(false)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  const { width } = useWindowSize()

  const onSidebarOpen = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen)
  }

  useEffect(() => {
    if (width < breakpoints.values.md) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [width])

  const wrapHomeLink = (content: any) =>
    homeLink ? <Link route={homeLink}>{content}</Link> : content

  return (
    <SC.Header className={className} backgroundColor={backgroundColor}>
      <SC.Wrapper maxWidth="xl" disableGutters>
        {!isMobile && (
          <SC.MuiToolbar>
            <SC.Logo>{wrapHomeLink(<SC.LogoImage src={logo.src} alt={logo.alt} />)}</SC.Logo>
            <SC.MobileList>
              {menu &&
                menu.map((item, index) => (
                  <SC.MobileListItem key={index}>
                    <SC.MobileListItemLink
                      className={item.active ? 'active' : item.special ? 'special' : ''}
                      {...item}
                    />
                  </SC.MobileListItem>
                ))}
            </SC.MobileList>
          </SC.MuiToolbar>
        )}
        {isMobile && (
          <>
            <SC.MuiToolbar className={isMobileMenuOpen ? 'mobile-menu-open-toolbar' : ''}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => onSidebarOpen()}
              >
                <SC.Icons icon={Icons.menu} />
              </IconButton>
              <SC.Logo>{wrapHomeLink(<SC.LogoImage src={logo.src} alt={logo.alt} />)}</SC.Logo>
              <Link {...props.connectLinkProps}>
                <IconButton edge="start" color="inherit" aria-label="menu">
                  <SC.Icons icon={Icons.profile} />
                </IconButton>
              </Link>
            </SC.MuiToolbar>
            <Hidden mdUp implementation="css">
              <SC.MuiDrawer variant="persistent" anchor="left" open={isMobileMenuOpen}>
                <SC.MobileMenu>
                  <SC.MobileList>
                    {menu &&
                      menu.map((item, index) => (
                        <SC.MobileListItem key={index}>
                          <SC.MobileListItemLink
                            className={item.active ? 'active' : item.special ? 'special' : ''}
                            {...item}
                          />
                        </SC.MobileListItem>
                      ))}
                  </SC.MobileList>
                  <SC.MuiDivider />
                  <SC.MobileButtons>
                    {menuMobile &&
                      menuMobile.signup &&
                      menuMobile.signup.map((item, index) => (
                        <SC.MobileLinkButton key={index} {...item} variant="outlined">
                          {item.text}
                        </SC.MobileLinkButton>
                      ))}
                  </SC.MobileButtons>
                  <SC.MuiDivider />
                  <SC.MobileList>
                    {menuMobile &&
                      menuMobile.others &&
                      menuMobile.others.map((item, index) => (
                        <SC.MobileListItem key={index}>
                          <SC.MobileListTinyItemLink {...item} />
                        </SC.MobileListItem>
                      ))}
                  </SC.MobileList>
                </SC.MobileMenu>
                <SC.CloseIconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={() => onSidebarOpen()}
                >
                  <SC.Icons icon={Icons.close} />
                </SC.CloseIconButton>
              </SC.MuiDrawer>
            </Hidden>
          </>
        )}
      </SC.Wrapper>
    </SC.Header>
  )
}

export default Header
