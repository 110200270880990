import React, { FC } from 'react'

import ImageGatsby, { ImageGatsbyProps } from '../ImageGatsby'
import { ImgProps } from '../../types/image'

export type ImageProps = ImageGatsbyProps | ImgProps

const Image: FC<ImageProps> = (props) => {
  return (props as ImageGatsbyProps)?.image ? (
    <ImageGatsby {...(props as ImageGatsbyProps)} />
  ) : (
    <img {...props} />
  )
}

export default Image
