import { useMemo } from 'react'

import { Contentful_Configuration } from '../types/gatsby-types'
import { FooterProps, GroupsProps } from '../components/Footer'
import { useIntl } from '../intl'
import { LinkIconTransformer, LinkTextTransformer } from '../relay/Link/transform'

interface useFooter {
  footerProps: FooterProps
}

export const useFooter = (configuration: Contentful_Configuration, locale: string): useFooter => {
  const intl = useIntl()
  const footerProps = useMemo(
    (): FooterProps => ({
      logo: { alt: 'LiberteClasse', src: '/images/common/liberteclass-logo-white.png' },
      groups: configuration.footerSectionsCollection?.items?.map(
        (entry): GroupsProps => ({
          title: entry?.topLink?.label ?? '',
          links: entry?.linksCollection?.items?.map(LinkTextTransformer),
        })
      ),
      extraLinks: configuration?.footerLegalsCollection?.items?.map(LinkTextTransformer),
      socials: configuration.socialLinksCollection?.items?.map(LinkIconTransformer),
      textCopyright: intl('footer_copyright'),
      address: {
        name: intl('address_name'),
        city: intl('address_city'),
        street: intl('address_street'),
        zipCode: intl('address_zipcode'),
      },
    }),
    [
      configuration.footerSectionsCollection?.items,
      configuration?.footerLegalsCollection?.items,
      configuration.socialLinksCollection?.items,
      intl,
    ]
  )

  return {
    footerProps,
  }
}
