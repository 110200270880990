import React, { FC } from 'react'
import { ThemeProvider, createGenerateClassName } from '@material-ui/core/styles'
import { Paper } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { IntlProvider } from 'react-intl'

import getTheme from '../../theme'
import Seo, { SeoProps } from '../Seo'
import DefaultHead from '../DefaultHead'
import { Contentful_Configuration } from '../../types/gatsby-types'

type AppProps = {
  seo: SeoProps
  configuration: Contentful_Configuration
  locale: string
}

const App: FC<AppProps> = (props) => {
  const { children, seo, locale, configuration } = props
  return (
    <StyledThemeProvider theme={getTheme()}>
      <ThemeProvider theme={getTheme()}>
        <IntlProvider locale={locale} messages={configuration.translations}>
          <CssBaseline />
          <DefaultHead />
          <Seo {...seo} />
          <Paper elevation={0}>{children}</Paper>
        </IntlProvider>
      </ThemeProvider>
    </StyledThemeProvider>
  )
}

export default App
