import React, { FC, memo, ReactNode } from 'react'
import cx from 'classnames'

import Layout from '../../components/Layout'
import Footer, { FooterProps } from '../../components/Footer'
import Header, { HeaderProps } from '../../components/Header'

export type PageTemplateProps = {
  className?: string
  headerProps: HeaderProps
  footerProps: FooterProps
  children?: ReactNode
}

const PageTemplate: FC<PageTemplateProps> = (props) => {
  const { className, headerProps, footerProps, children } = props

  return (
    <Layout
      className={cx(className)}
      header={
        <>
          <Header {...headerProps} />
        </>
      }
      footer={
        <>
          <Footer {...footerProps} />
        </>
      }
    >
      {children}
    </Layout>
  )
}

export default memo(PageTemplate)
