import styled from 'styled-components'

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  & > main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`
