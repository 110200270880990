import { Contentful_Asset, Maybe } from '../../types/gatsby-types'
import { ImageGatsbyProps } from '../../components/ImageGatsby'

export default (props: Maybe<Contentful_Asset>) => ({
  image: props?.localAsset?.childImageSharp?.gatsbyImageData ?? {},
  width: props?.localAsset?.childImageSharp?.gatsbyImageData?.images?.width ?? null,
  height: props?.localAsset?.childImageSharp?.gatsbyImageData?.images?.height ?? null,
  alt: props?.title ?? '',
  description: props?.description ?? '',
})

export const DefaultImageTransformer = (): ImageGatsbyProps => ({
  image: '',
  alt: '',
})
