import styled from 'styled-components'

export const Svg = styled.svg<{ color: string }>`
  color: ${({ color }) => color};
`

export const Button = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
`
