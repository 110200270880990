import { useMemo } from 'react'
import { WindowLocation } from '@reach/router'

import {
  Contentful_Configuration,
  Contentful_Link,
  Contentful_Page,
  Maybe,
} from '../types/gatsby-types'
import { HeaderProps } from '../components/Header'
import { LinkTextTransformer } from '../relay/models'

interface UseHeader {
  headerProps: HeaderProps
}

export const useHeader = (
  configuration: Contentful_Configuration,
  locale: string,
  location: WindowLocation,
  page?: Contentful_Page | { color?: string; slug?: string }
): UseHeader => {
  const headerProps = useMemo((): HeaderProps => {
    const currentSlug = ('/' + (page?.slug ?? '')).replace('//', '/')
    const linkTransformer = (link: Maybe<Contentful_Link>) => {
      const transformedLink = LinkTextTransformer(link)
      return {
        ...transformedLink,
        active: currentSlug === link?.url,
      }
    }
    return {
      homeLink: currentSlug !== '/' || !page?.slug ? '/' : '',
      logo: { alt: 'LiberteClasse', src: '/images/common/liberteclass-logo-v2.png' },
      backgroundColor: page?.color ?? 'transparent',
      menu: configuration.mainLinksCollection?.items?.map(linkTransformer),
      menuMobile: {
        signup: configuration.mobileRegisterLinksCollection?.items?.map(linkTransformer),
        others: configuration.mobileExtraLinksCollection?.items?.map(linkTransformer),
      },
      connectLinkProps: configuration.mobileHeadIcon
        ? linkTransformer(configuration.mobileHeadIcon)
        : undefined,
    }
  }, [
    page?.slug,
    page?.color,
    configuration.mainLinksCollection?.items,
    configuration.mobileRegisterLinksCollection?.items,
    configuration.mobileExtraLinksCollection?.items,
    configuration.mobileHeadIcon,
  ])

  return {
    headerProps,
  }
}
