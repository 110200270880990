import React, { FC, useMemo } from 'react'

import { LinkProps, TextLinkProps } from '../Link'
import { ImgProps } from '../../types/image'
import { Icons } from '../Icon/types'

import * as SC from './styled'

export type GroupsProps = {
  title: string
  links?: LinkProps[]
}

export type AddressProps = {
  name: string
  street: string
  city: string
  zipCode: string
}

export type SocialType = LinkProps & {
  icon: Icons
}

export type FooterProps = {
  className?: string
  logo?: ImgProps
  groups?: GroupsProps[]
  extraLinks?: TextLinkProps[]
  socials?: SocialType[]
  textCopyright?: string
  address: AddressProps
}

const Footer: FC<FooterProps> = (props) => {
  const { className, logo, groups, extraLinks, socials, textCopyright, address } = props

  const renderSocials = useMemo(() => {
    return (
      socials && (
        <SC.Socials>
          {socials?.map((social, index) => {
            return (
              <li key={`footer-social-${index}`}>
                <SC.SocialsLink
                  {...social}
                  text={social.text}
                  icon={undefined}
                  aria-label={social.icon}
                >
                  <SC.SocialsIcon icon={social.icon} />
                </SC.SocialsLink>
              </li>
            )
          })}
        </SC.Socials>
      )
    )
  }, [socials])

  const renderLinks = useMemo(() => {
    return (
      groups && (
        <SC.Groups>
          {groups?.map((group, index) => (
            <SC.GroupsItem key={index}>
              <SC.GroupsTitle>{group.title}</SC.GroupsTitle>
              {group.links?.map((link, index) => (
                <SC.LinksLi key={`footer-link-${index}`}>
                  <SC.LinksLink {...link}>{link?.text}</SC.LinksLink>
                </SC.LinksLi>
              ))}
            </SC.GroupsItem>
          ))}
        </SC.Groups>
      )
    )
  }, [groups])

  const renderMoreLinks = useMemo(() => {
    return (
      extraLinks && (
        <SC.OtherLinks>
          {extraLinks?.map((link, index) => {
            return (
              <li key={`footer-extralink-${index}`}>
                <SC.LegalLink {...link}>{link?.text}</SC.LegalLink>
              </li>
            )
          })}
        </SC.OtherLinks>
      )
    )
  }, [extraLinks])

  const renderAddress = useMemo(() => {
    return (
      address && (
        <SC.Address>
          <li>
            <SC.Text>{address?.name}</SC.Text>
          </li>
          <li>
            <SC.Text>{address?.street}</SC.Text>
          </li>
          <li>
            <SC.Text>{`${address?.zipCode} ${address?.city}`}</SC.Text>
          </li>
        </SC.Address>
      )
    )
  }, [address])

  return (
    <SC.Footer className={className}>
      <SC.Wrapper maxWidth="xl">
        <SC.Content>
          {logo && (
            <SC.Logo>
              <SC.LogoImage src={logo.src} alt={logo.alt} />
            </SC.Logo>
          )}
          {renderSocials}
        </SC.Content>
        <SC.Content>
          {renderLinks}
          {renderAddress}
        </SC.Content>
        {(textCopyright || extraLinks) && (
          <SC.Subcontent>
            {textCopyright && <SC.Text>{textCopyright}</SC.Text>}
            {renderMoreLinks}
          </SC.Subcontent>
        )}
      </SC.Wrapper>
    </SC.Footer>
  )
}

export default Footer
