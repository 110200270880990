import styled from 'styled-components'

import Icon from '../Icon'

export const LinkButton = styled.div``

export const IconIn = styled(Icon)`
  width: 1.8rem;
  height: 1.8rem;
  margin-left: 1rem;
`
