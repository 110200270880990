import React, { FC } from 'react'

import router from '../../router'
import { Icons } from '../Icon/types'

import * as SC from './styled'

export type LinkProps = {
  children?: React.ReactNode
  className?: string
  link?: string
  target?: string
  title?: string
  role?: string
  tabIndex?: number
  route?: string
  routeParams?: { [key: string]: string }
  rel?: string
  text?: string
  onClick?: (ev?: React.SyntheticEvent) => void
  noHref?: boolean
  active?: boolean
  icon?: Icons
  iconColor?: string
  special?: boolean
}

export type TextLinkProps = LinkProps & { text?: string }

const Link: FC<LinkProps> = ({
  children,
  className,
  link,
  target,
  title,
  role,
  tabIndex,
  route,
  routeParams,
  rel,
  text,
  icon,
  iconColor,
  onClick = () => undefined,
}) => {
  const renderIcon = () =>
    icon && <SC.IconIn icon={Icons[icon]} color={iconColor ? iconColor : ''} />

  const renderDefault = () => (
    <SC.LinkA
      className={className}
      onClick={onClick}
      role={role}
      tabIndex={tabIndex}
      title={title}
      aria-label={title}
    >
      {children || text || ' '}
      {icon && renderIcon()}
    </SC.LinkA>
  )

  const renderAsLink = () => {
    if (link) {
      return (
        <SC.LinkA
          className={className}
          title={title}
          href={link}
          aria-label={title}
          target={target || '_self'}
          onClick={onClick}
          rel={rel || 'noreferrer'}
          role={role}
          tabIndex={tabIndex}
        >
          {children || text || ' '}
          {icon && renderIcon()}
        </SC.LinkA>
      )
    }

    return (
      <SC.LinkGatsby
        to={routeParams ? router(route!, routeParams) : route ?? ''}
        className={className}
        title={title}
        aria-label={title}
        onClick={onClick}
        role={role}
        tabIndex={tabIndex}
        target={target}
      >
        {children || text || ' '}
        {icon && renderIcon()}
      </SC.LinkGatsby>
    )
  }

  return <>{link || route ? renderAsLink() : renderDefault()}</>
}

export default Link
